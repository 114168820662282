<template>
  <a-row>
    <a-col :span="22" :offset="1">
      <a-card
          title="Parcels"
          size="small"
          style="text-align: 'left'; margin-top: '10px'; background-color: '#FAFAFA'"
          :headStyle="{textAlign: 'left'}"
      >
        <a-input-search
          v-model:value="searchQuery"
          placeholder="Search Parcel"
          style="width: 100%; margin-bottom: 10px;"
          @search="onSearchParcel"
          @input="onSearchParcel"
        />
        <template v-for="(parcel) in activParcels" :key="parcel.id">
          <ParcelCard :parcel="parcel" />
        </template>
        <template v-if="data.length === 0">
          <a-empty />
        </template>
      </a-card>
    </a-col>
    <LoadingSpiner  v-if="loading"/>
  </a-row>
</template>

<script>
import api from "@/services/api";
import ParcelCard from "@/components/Parcel-Card.vue"
import LoadingSpiner from '@/components/Loading-Spiner.vue';
import { message } from "ant-design-vue";

export default {
  components: {
    ParcelCard,
    LoadingSpiner
  },
  data: () => ({
    loading: false,
    searchQuery: "",
    activParcels: {},
    data: [],
  }),
  methods: {
    onSearchParcel () {
      this.activParcels = this.data.filter(p => p.recipient.toLowerCase().includes(this.searchQuery.toLowerCase()) 
      || p.trackCodePP.toLowerCase().includes(this.searchQuery.toLowerCase()) 
      || p.contents.toLowerCase().includes(this.searchQuery.toLowerCase()))
    },
    normalizeParcels (data) {
      data.sort((a, b) => b.id - a.id)
      data.forEach(r => {
        this.data.push({
          id: r.id,
          trackCodePP: r.trackCode,
          cdek_number: (r.cdek_number) ? r.cdek_number : '',
          contents: r.name,
          recipient: r.recipient.fullName,
          status: r.status
        });
      });
      this.activParcels = this.data;
    },
    async getOrdersList() {
      this.loading = true;
        try {
          const response = await api.get("/parcel");
          this.normalizeParcels(response.data)
        } catch (error) {
          if (error.message === "Token not found") {
            message.error("Not authorized");
          } else {
            console.error("Error fetching orders:", error);
            message.error("No connect Try again later");
          }
        } finally {
          this.loading = false; 
        }
  }
  },
  mounted() {
    this.getOrdersList();
  }
}
</script>
