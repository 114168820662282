<template>
    <div class="spinner-container">
      <Spin size="large"/>
    </div>
  </template>
  
  <script>
  import { Spin } from 'ant-design-vue';
  
  export default {
    name: 'LoadingSpiner',
    components: {
      Spin
    }
  };
  </script>
  
  <style scoped>
  .spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5); 
    z-index: 9999; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
  