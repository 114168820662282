<template>
  <a-config-provider
      :theme="{
      token: {
        colorPrimary: '#FC0004',
      },
    }"
  >
  <Main-header v-if="$route.path === '/'" />
  <Secondary-header v-if="$route.path != '/'" />
  <router-view v-if="!loading" class="main-content"/>
  <loading-spiner v-if="loading" />
  </a-config-provider>
</template>


<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import {initInstance} from "@/services/api";
import publicApi from "@/services/publicApi";
import LoadingSpiner from '@/components/Loading-Spiner.vue';
import MainHeader from '@/components/Main-header.vue';
import SecondaryHeader from './components/Secondary-header.vue';

export default {
  components: {
    LoadingSpiner,
    MainHeader,
    SecondaryHeader
  },
  computed: {
  ...mapGetters(['isLogin', 'loading', 'webApp']),
  },
  methods: {
    ...mapMutations(['setIsLogin', 'setLoading', 'setTgUserData']),
    ...mapActions(['initWebApp']),
  },
  mounted() {
    this.initWebApp();
      if (!this.isLogin) {
        if (this.webApp?.initData) {
          publicApi.post("/auth", {initData: this.webApp.initData})
          .then(r => {
            initInstance(r.data.token);
            this.setIsLogin(true);
            this.setLoading(false);
          })
          .catch(error => {
            if ([401, 403].includes(error.r.status)) {
              this.setLoading(false);
              this.$router.push("error");
            }
          });
        } else {
          console.error('TG api initData not present');
          this.setLoading(false);
          this.$router.push("about");
        }
    }
  }
}
</script>

<style>
@font-face {
    font-family: 'Raleway';
    src: url('@/assets/fonts/Raleway/Raleway-VariableFont_wght.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
  }
  
@font-face {
  font-family: 'Raleway';
  src: url('@/assets/fonts/Raleway/Raleway-Italic-VariableFont_wght.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

body{
  background-color: #fff;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #fff;
}

.main-content{
  padding-top: 75px;
}


</style>
