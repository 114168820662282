export default {
  state() {
    return {
      isLogin: false,
      loading: true,
      webApp: null,
    };
  },
  getters: {
    isLogin(state) {
      return state.isLogin;
    },
    loading(state) {
      return state.loading;
    },
    webApp(state) {
      return state.webApp;
    },
  },
  mutations: {
    setIsLogin(state, isLogin) {
      state.isLogin = isLogin;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setWebApp(state, webApp) {
      state.webApp = webApp;
    },
  },
  actions: {
    initWebApp({ commit }) {
      const WebApp = window.Telegram?.WebApp;
      if (WebApp) {
        commit("setWebApp", WebApp);
      } else {
        return commit("setWebApp", null);
      }
    },
  },
};
