<template>
  <div class="home" style="height: 100%">
    <div class="slider-baner">
      <a-carousel autoplay>
        <div> <img src="@/assets/images/pexels-cottonbro-studio-5077393.webp" alt="slide_1"> </div>
        <div><img src="@/assets/images/pexels-karolina-grabowska-4464482.webp" alt="slide_2"></div>
        <div><img src="@/assets/images/pexels-karolina-grabowska-4498152.webp" alt="slide_3"></div>
        <div><img src="@/assets/images/pexels-ketut-subiyanto-4246120.webp" alt="slide_4"></div>
        <div><img src="@/assets/images/pexels-kindel-media-6869042.webp" alt="slide_5"></div>
        <div><img src="@/assets/images/pexels-norma-mortenson-4391470.webp" alt="slide_6"></div>
      </a-carousel>
    </div>
    

    <a-row style="padding-top: 30px; padding-left: 20px; padding-right: 20px;" class="main-menu">
      <a-col :span="16">
          <router-link to="/addParcel">
            <a-button type="primary" size="large" block class="main-btn">
            <ContainerOutlined />
            Create Order
          </a-button>
          </router-link>
          <router-link to="/myParcels">
              <a-button type="primary" block size="large" class="main-btn">
              <SolutionOutlined />
              My orders
            </a-button>
          </router-link>
          <router-link to="/recipients">
            <a-button type="primary" block size="large" class="main-btn">
              <TeamOutlined />
              Recipients
            </a-button>
          </router-link>
          <a-button type="primary" block size="large" class="main-btn" href="https://t.me/parcelsupport_bot">
            <QuestionCircleOutlined />
              Support
          </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
  import {TeamOutlined, SolutionOutlined, ContainerOutlined, QuestionCircleOutlined} from '@ant-design/icons-vue';
</script>

<script>

export default {
  name: 'HomeView',
  components: {
  }
}
</script>

<style scoped>
.home::before{
  content: '';
  display: block;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: -20vh;
  background-color: #FC0004;
  width: 45vh;
  height: 22vh;
  border-radius: 50vh 50vh 0 0;
}
.gutter-box {
  padding-top: 18px;
}

.main-menu{
  display:flex;
  justify-content: center;
  align-items: center;
}

.main-btn {
  font-family: Raleway;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin-bottom: 10px;
}

.slider-baner{
  position: relative;
}


:deep(.slick-slide) {
  text-align: center;
  height: 142px;
  overflow: hidden;
}
:deep(.slick-slide img) {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
}
</style>

