<template>
  <a-row>
    <a-col :span="22" :offset="1">
      <a-card
          size="small"
          style="text-align: left; margin-top: 10px; background-color: #FAFAFA">
        <template #title>
          <router-link to="/editRecipient">
            <a-button type="dashed" size="small"><PlusSquareOutlined /> Add recipient</a-button>
          </router-link>
        </template>
        <a-input-search
          v-model:value="searchQuery"
          placeholder="Search Recipient"
          style="width: 100%; margin-bottom: 10px;"
          @search="onSearchRecipient"
          @input="onSearchRecipient"
        />
        <a-card size="small" 
        class="recipient-card"
        v-for="(item, i) in showedRecipients" :key="i" 
        :bodyStyle="{paddingLeft: '0px', paddingTop: '0px', paddingBottom: '0px'}"
        >
          <template #title>
            <UserOutlined  class="name-icon"/>
            <span>{{ item.name }}</span>
          </template>
          <template #extra>
            <a-button size="small" type="dashed" danger @click="showModal(item)">Delete</a-button>
          </template>
          <p>{{item.address}}</p>
        </a-card>
      </a-card>
    </a-col>
    <LoadingSpiner v-if="loading"/>
    <ConfirmModal ref="confirmModal"/>
  </a-row>
</template>

<script>
import {PlusSquareOutlined, UserOutlined} from "@ant-design/icons-vue";
import api from "@/services/api";
import LoadingSpiner from '@/components/Loading-Spiner.vue';
import { message } from "ant-design-vue";
import ConfirmModal from '@/components/ConfirmModal.vue'

export default {
  components: {
    PlusSquareOutlined, 
    LoadingSpiner, 
    UserOutlined,
    ConfirmModal
  },
  data: () => ({
    loading: false,
    showedRecipients: [],
    searchQuery: '',
    columns: [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Address',
        dataIndex: 'address',
        maxWidth: '50%'
      },
      {
        title: 'Action',
        dataIndex: 'act',
        align: 'center',
      },
    ],
    data: [],

  }),
  methods: {
    async showModal(recipient) {
      try {
        await this.$refs.confirmModal.show({
        okButton:"Delete", 
        title:"Delete Recipient",
        content: recipient.name
       });
        this.deleteRecipient(recipient)
      } catch {
        console.log('Cancelled');
      }
    },
    async deleteRecipient(recipient) {
      this.loading = true;
      try {
        await api.delete(`/recipient-template/${recipient.act}`);
        message.success('Recipient deleted successfully');
        this.loadRecipients();
      } catch (error) {
          console.error("Error delete recipient:", error);
          message.error("Failed to delete recipients");
      } finally {
        this.loading = false;
      }
    },
    onSearchRecipient () {
      this.showedRecipients = this.data.filter(r => r.name.toLowerCase().includes(this.searchQuery.toLowerCase()) 
      || r.address.toLowerCase().includes(this.searchQuery.toLowerCase()) )
    },
    normalizeClientRecipients (data) {
      this.data = [];
      data.forEach( r => {
          this.data.push({
            name: r.fullName,
            address: r.address[0].cityAddress ? r.address[0].cityAddress.address : r.address[0].officeAddress.address,
            act: r.id
          });
        });
      this.showedRecipients = this.data;
    },

    async loadRecipients() {
      this.loading = true;
      try {
        const response = await api.get("/recipient-template");
        this.normalizeClientRecipients(response.data)
      } catch (error) {
        if (error.message === "Token not found") {
            message.error("Not authorized");
            this.recipientForDelete = null 
          } else {
            console.error("Error loading recipients:", error);
            message.error("Failed to load recipients");
            this.recipientForDelete = null 
        }
      } finally {
        this.loading = false;
        this.recipientForDelete = null 
      }
    }
  },
  mounted() {
    this.loadRecipients();
  }
}
</script>
<style scoped>
.recipient-card{
    border: 1px solid rgba(255, 0, 0, 0.15);
    border-radius: 6px 20px 6px 20px;
    width: 100%; 
    padding-left: 10px; 
    padding-bottom: 0px;
    margin-bottom: 5px;
}
.name-icon{
    position: absolute;
    top: 10px;
    left: 5px;
    color: #FC0004;
    height: 1rem;
    width: auto;
}
</style>
