import { createStore } from "vuex";
import editRecipient from "./editRecipient.js";
import userData from "./userData.js";

export default createStore({
  modules: {
    editRecipient,
    userData,
  },
});
