import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AddParcel from "../views/addParcel.vue";
import MyParcels from "../views/myParcels.vue";
import ClientRecipients from "../views/ClientRecipients.vue";
import EditRecipient from "../views/EditRecipient.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import ParcelDetails from "@/views/ParcelDetails.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/MyParcels",
    name: "myParcels",
    component: MyParcels,
  },
  {
    path: "/addParcel",
    name: "addParcel",
    component: AddParcel,
  },
  {
    path: "/recipients",
    name: "ClientRecipients",
    component: ClientRecipients,
  },
  {
    path: "/editRecipient/:id",
    name: "EditRecipient",
    component: EditRecipient,
  },
  {
    path: "/editRecipient",
    name: "AddRecipient",
    component: EditRecipient,
  },
  {
    path: "/error",
    name: "ErrorPage",
    component: ErrorPage,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/MyParcels/:track",
    component: ParcelDetails,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
