import axios from "axios";

const instance = axios.create({
  baseURL: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Token ${process.env.SUGGESTION_API_KEY}`,
  },
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
