<template>
    <a-modal 
    v-model:open="open" 
    :title="title" 
    centered
    >
      <template #footer>
        <a-row>
          <a-col :span="11">
            <a-button class="modal-btn" key="back" @click="close('cancel')">{{cancelButton}}</a-button>
          </a-col>
          <a-col :span="11" :offset="2">
            <a-button class="modal-btn" type="primary" @click="close('confirm')">{{ okButton }}</a-button>
          </a-col>
        </a-row>
      </template>
      <p v-if="content">{{ content }}</p>
    </a-modal>
</template>

<script>
export default {
    name: 'ConfirmModal',
    data() {
        return {
            open: false,
            okButton: null,
            content: null,
            title: null,
            cancelButton: 'Cancel',
            resolve: null,
            reject: null,
        }
    },
    methods: {
        show(data) {
            this.okButton = data.okButton 
            this.title = data.title
            this.content = data.content
            return new Promise((resolve, reject) => {
                this.open = true;
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        close(action) {
            if (action === 'confirm') {
                this.resolve();
            } else {
                this.reject();
            }
            this.open = false;
        }
    }
}
</script>

<style scoped>
.modal-btn{
    width: 100%;
}
</style>