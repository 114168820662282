<template>
    <div class="card-wrapper">
        <img src="@/assets/Icon/box_delivery_package_parcel_icon.svg" alt="parcel" class="title-icon">
        <a-descriptions class="parcel-card">
            <template #title >
                <span @click="copyToClipboard(parcel.trackCodePP)">Parcel {{parcel.trackCodePP}}</span>
            </template>
            <template #extra>
                <CopyOutlined @click="copyToClipboard(parcel.trackCodePP)" style="margin-right: 10px"/>
            </template>
            <a-descriptions-item label="Recipient" class="lable-parcel-card">
                <UserOutlined class="lable-icon" />
                {{parcel.recipient}}
            </a-descriptions-item>
            <a-descriptions-item label="Track" class="lable-parcel-card">
                <BarcodeOutlined class="lable-icon"/>
                {{parcel.trackCodePP}}
            </a-descriptions-item>
            <a-descriptions-item label="Contents" class="lable-parcel-card">
                <img src="@/assets/Icon/box_delivery_package_parcel_contains_icon.svg" class="lable-icon" alt="contans">
                {{ parcel.contents }}
            </a-descriptions-item>
            <a-descriptions-item label="Status" class="lable-parcel-card" >
                <img src="@/assets/Icon/box_delivery_package_parcel_new.svg" class="lable-icon" alt="New">
                {{ status }}
            </a-descriptions-item>
            <a-descriptions-item >
                <a-button type="dashed" block @click="goToDetails()">Show Details</a-button>
            </a-descriptions-item>
        </a-descriptions>
    </div>
</template>

<script setup>
import {
    UserOutlined, 
    BarcodeOutlined, 
    CopyOutlined
    } from '@ant-design/icons-vue';
</script>

<script>
import { message } from "ant-design-vue";

export default {
    name: 'ParcelCard',
    props: ['parcel'],
    computed: {
        status() {
            return this.parcel.status.toLowerCase().replace(/_/g, " ")
        }
    },
    methods: {
        copyToClipboard(trackCodePP) {
        navigator.clipboard.writeText(trackCodePP)
            .then(() => {
            message.success('Track copied to clipboard');
            })
            .catch(err => {
            console.error(err);
            message.error('Failed to copy address');
            });
        },
        goToDetails () {
            const encodedContents = encodeURIComponent(this.parcel.contents);
            const encodedRecipient = encodeURIComponent(this.parcel.recipient);
            this.$router.push(`/MyParcels/${this.parcel.trackCodePP}?contents=${encodedContents}&recipient=${encodedRecipient}`);
        }
    }

}
</script>

<style>
.card-wrapper{
    position: relative;
}

.parcel-card {
    border: 1px solid rgba(255, 0, 0, 0.15);
    border-radius: 6px 20px 6px 20px;
    margin-bottom: 10px;
    padding: 10px;
}

.lable-parcel-card{
    position: relative;
    padding-left: 17px;
    text-transform: capitalize;
}

.lable-icon{
    position: absolute;
    top: 5px;
    left: -1px;
    color: #FC0004;
    height: 1rem;
    width: auto;
}

.title-icon{
    color: #FC0004;
    position: absolute;
    top: 16px;
    left: calc(50% - 128px);
    height: 1rem;
    width: auto;
}
</style>