<template>
    <a-row class="main-header">
      <a-col span="4" :offset="1">
        <button @click="$router.go(-1)" size="small" class="back-btn">
          <ArrowLeftOutlined />
        Back
      </button>
    </a-col>
      <a-col span="12" :offset="2">
        <router-link :to="'/'">
          <img src="@/assets/images/PP_Logo_black.svg" alt="ParcelPoint" class="main-logo"/>
        </router-link>
      </a-col>
    </a-row>
</template>

<script setup>
  import {ArrowLeftOutlined} from '@ant-design/icons-vue';
</script>

<script>
export default {
    name: 'Secondary-Header',
}
</script>

<style scoped>
.main-header{
  padding-top: 20px;
  padding-bottom: 20px;
  height: auto;
  position:fixed;
  background-color: #fff;
  z-index: 90;
  left: 0px;
  top: 0px;
  right: 0px;
}

.main-logo{
  width: 150px;
  height: auto;  
}

.back-btn{
  font-family: Raleway;
  background-color: #101010;
  border: none;
  border-radius: 33px;
  padding: 0; 
  width: 89px; 
  height: 33px; 
  color: white;
  font-size: 16px;
}

@media (max-width: 340px) {
  .back-btn {
    width: 65px;
    height: 28px; 
    font-size: 12px; 
  }
}
</style>