<template>
    <a-row>
    <a-col :span="22" :offset="1">
        <a-card
            v-if="!loading"
            size="small"
            style="text-align: left; margin-top: 10px; background-color: #FAFAFA"
            :headStyle="{textAlign: 'left'}"
        >
        <template #title>
            <span @click="copyToClipboard(track)">Parcel {{track}}</span>
            <CopyOutlined @click="copyToClipboard(track)" style="margin: 0 5px"/>
        </template>
        <a-timeline v-if="!notFound" style="padding-top: 20px">
            <a-timeline-item>
                <template #dot>
                    <CheckCircleOutlined class="main-icon"/>
                </template>
                <a-descriptions style="text-transform: capitalize;" class="parcel-card">
                    <template #title>
                        <span v-if="!parcelData.statuses || parcelData.statuses.length === 0 && parcelData.status" >{{ parcelData.status }}</span>
                        <span v-else-if="parcelData.statuses && parcelData.statuses.length > 0" >{{ parcelData.statuses[0].status }}</span>
                    </template>
                    <a-descriptions-item label="Recipient" class="lable-parcel-card">
                        <UserOutlined class="lable-icon" />
                        {{recipient}}
                    </a-descriptions-item>
                    <a-descriptions-item label="Contents" class="lable-parcel-card">
                        <img src="@/assets/Icon/box_delivery_package_parcel_contains_icon.svg" class="lable-icon" alt="contans">
                        {{ contents }}
                    </a-descriptions-item>
                    <a-descriptions-item label="Direction" class="lable-parcel-card" v-if='parcelData.from && parcelData.to'>
                            <img src="@/assets/Icon/direction_icon.svg" class="lable-icon" alt="direction">
                        <span>{{parcelData.from.countryCode}}</span>
                            <SwapRightOutlined style="transform: scaleX(2); margin: 0 10px;"/>
                        <span>{{parcelData.to.countryCode}}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="Route" class="lable-parcel-card" v-else-if="parcelData.route">
                            <img src="@/assets/Icon/direction_icon.svg" class="lable-icon" alt="direction">
                        <span>{{parcelData.route.description}}</span>
                        <SwapRightOutlined v-if='parcelData.to.countryCode' style="transform: scaleX(2); margin: 0 10px;"/>
                        <span v-if='parcelData.to.countryCode'>{{parcelData.to.countryCode}}</span>
                        <span v-else-if="parcelData.from.countryCode">{{parcelData.from.countryCode}}</span>
                    </a-descriptions-item>
                </a-descriptions>
            </a-timeline-item>
            <template v-if="parcelData.statuses">
                <a-timeline-item v-for="item, i in parcelData.statuses" :key="i" color="gray">
                <div style="text-transform: capitalize;">{{ item.status }}</div>
                <span style="margin-right: 5px;">{{item.date}}</span>
                <span v-if='item.location'>{{item.location}}</span>
            </a-timeline-item>
            </template>
            <template v-if="!parcelData.statuses || parcelData.statuses.length === 0">
                <a-timeline-item color="gray">
                <div style="text-transform: capitalize;">{{ parcelData.status }}</div>
                <span style="margin-right: 5px;">
                    The parcel has just been created and is awaiting processing by the operator
                </span>
            </a-timeline-item>
        </template>
        </a-timeline>
        <template v-if="notFound">
                <a-card title="Sorry, try again later" :bordered="false">
                <p style="color: red;">{{ notFound }}</p>
                </a-card>
        </template>
      </a-card>
    </a-col>
    <LoadingSpiner  v-if="loading"/>
  </a-row>
</template>

<script setup>
import {
    SwapRightOutlined,
    UserOutlined,
    CheckCircleOutlined,
    CopyOutlined
    } from '@ant-design/icons-vue';
</script>

<script>
import LoadingSpiner from '@/components/Loading-Spiner.vue';
import { message } from "ant-design-vue";
import ApiPublic from "@/services/parcelApi";

export default {
    name: 'ParcelDetails',
    components: {
    LoadingSpiner
    },
    data () {
        return {
            track: this.$route.params.track,
            recipient: decodeURIComponent(this.$route.query.recipient),
            contents: decodeURIComponent(this.$route.query.contents),
            parcelData: {},
            loading: false,
            notFound: false,
        }
    },
    methods: {
        copyToClipboard(trackCodePP) {
        navigator.clipboard.writeText(trackCodePP)
            .then(() => {
            message.success('Track copied to clipboard');
            })
            .catch(err => {
            console.error(err);
            message.error('Failed to copy address');
            });
        },
        normalizeData(data) {
            data.route.description = data.route.description.toLowerCase()
            data.status = data.status.toLowerCase().replace(/_/g, " ")
            data.statuses.forEach(status => {
                status.status = status.status.toLowerCase().replace(/_/g, " ");
                let date = new Date(status.date);
                status.date = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
            });
            this.parcelData = data;
        },
        async getParcelData () {
            this.loading = true;
            try {
            const response = await ApiPublic.getTrack(this.track); 
                this.normalizeData(response.data)
            } catch (error) {
                this.notFound = error.response.data.message;
                message.error(error.response.data.message);
            } finally {
                this.loading = false; 
            }  
        },
        
    },
    mounted () {
        this.getParcelData()
    }
}
</script>

<style scoped>
.parcel-card {
    border: 1px solid rgba(255, 0, 0, 0.15);
    border-radius: 6px 20px 6px 20px;
    padding:  10px 0 0 10px;
}
.main-icon{
    color: #FC0004; 
    transform: scale(1.5); 
    margin: 0 10px; 
    padding-top: 1rem;
}

.lable-parcel-card{
    position: relative;
    padding-left: 2rem;  
    text-transform: capitalize;
    padding-bottom: 10px !important;
}

.lable-icon{
    position: absolute;
    top: 5px;
    left: -1px;
    color: #FC0004;
    height: 1rem;
    width: auto;
}
</style>