import axios from "axios";

const instance = axios.create({
  baseURL: process.env.PROTECTED_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const initInstance = (token) => {
  if (!token) {
    return Promise.reject(new Error("Token not found"));
  }
  instance.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export default instance;
