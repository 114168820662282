<template>
    <a-row class="main-header">  
      <a-col span="4" class="main-logo">
        <router-link :to="'/'"><img src="@/assets/Icon/Tittle_icon_PP.svg" alt="PP"></router-link>
      </a-col>
      <a-col span="20" class="title-header">
        <div>Welcome to Parcelpoint</div>
        <div class="user-name">{{webApp?.initDataUnsafe?.user.username}}</div>
      </a-col>
    </a-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Main-Header',
    computed: {
  ...mapGetters(['webApp']),
  },
}
</script>

<style scoped>
.main-header{
  padding-top: 20px;
  padding-bottom: 20px;
  height: auto;
  position:fixed;
  background-color: #fff;
  z-index: 90;
  left: 0px;
  top: 0px;
  right: 0px;
}

.title-header{
  font-family: Raleway;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  text-align: left;
  color: #606060;
}

.user-name{
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;
  color: #101010;
}
.main-logo{
  width: 150px;
  height: auto;  
}

</style>