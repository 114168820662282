import axios from "axios";

const baseUrl = process.env.TRACKING_API_URL;

class parcelApi {
  getTrack(track_id) {
    return axios({
      url: `${baseUrl}/parcel/track/${track_id}`,
      method: "GET",
    });
  }
}

export default new parcelApi();
